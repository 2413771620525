.gallery {
	display: flex;
	flex-wrap: wrap;
}

.gallery > div {
	margin: 1rem;
	cursor: pointer;
	position: relative;
}

.gallery img,
.gallery video {
	max-width: 200px;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 999;
}

.modal img,
.modal video {
	max-height: 90%;
	max-width: 90%;
}
